import '@khmyznikov/pwa-install';

setTimeout(() => {
  var pwaInstall = document.querySelector("pwa-install");
  if (pwaInstall == null) return;


  const lastAskedTime = localStorage.getItem("pwa_install_asked_at_time");
  const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

  if (document.getElementById("signed_in") && !pwaInstall.isUnderStandaloneMode && window.innerWidth <= 768) {
    if (!lastAskedTime || (new Date() - new Date(lastAskedTime)) > oneMonthInMilliseconds) {
      pwaInstall.showDialog();
      localStorage.setItem("pwa_install_asked_at_time", new Date().toISOString());
    }
  }

  document.querySelectorAll(".pwa-install").forEach(function (element) {
    element.addEventListener("click", function () {
      pwaInstall.showDialog();
    });
  });

  if (pwaInstall.isUnderStandaloneMode) {
    document.querySelectorAll(".pwa-install").forEach(function (element) {
      element.classList.add("hidden");
    });
  }
}, 1000)
