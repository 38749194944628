import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    let style = window.getComputedStyle(this.listTarget);
    let overflowY = style.getPropertyValue("overflow-y");
    let hasNextPage = this.listTarget.dataset.nextPage == "true";

    if (hasNextPage && overflowY == "scroll") {
      this.listTarget.style.height = `${window.innerHeight - this.listTarget.offsetTop}px`;
      let childDiv = document.createElement("div");
      childDiv.style.height = "50px";
      this.listTarget.appendChild(childDiv);
      // this.listTarget.style.maskImage = "linear-gradient(to bottom, black 90%, transparent)";
    } else {
      this.listTarget.style.overflowY = `auto`;
    }
  }
}
